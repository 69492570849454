import { Carousel, CarouselItem, Heading, Link, LinkButton, Picture } from '@troon/ui';
import { IconChevronRight } from '@troon/icons';
import { For, Show } from 'solid-js';
import { NoHydration } from 'solid-js/web';
import { createAsync } from '@solidjs/router';
import { Title } from '@solidjs/meta';
import { HeroVideo } from '../../../components/hero/video';
import { Content } from '../../../components/content';
import { TeeTimeSearch } from '../../../components/tee-time-search';
import { getConfigValue } from '../../../modules/config';
import { gql } from '../../../graphql';
import { cachedQuery } from '../../../graphql/cached-get';
import type { RouteDefinition } from '@solidjs/router';
import type { JSX } from 'solid-js';

export function LoggedOut() {
	const data = createAsync(() => getHomeData({}));

	return (
		<>
			<HeroVideo>
				<Content class="mb-24 flex flex-col gap-4 self-end">
					<Heading as="h1" class="capitalize">
						Your next round awaits
					</Heading>
					<p class="text-lg">Explore the world of Troon and find the perfect course for your next round.</p>
				</Content>
			</HeroVideo>
			<Title>Troon | Book tee times and earn rewards at Troon golf courses</Title>
			<div class="flex flex-col gap-16 md:gap-24">
				<Content class="flex flex-col gap-16 md:gap-24">
					<div class="relative -top-24 z-30 -mb-24 rounded bg-white p-4 shadow-lg">
						<TeeTimeSearch />
					</div>

					<NoHydration>
						<div class="flex flex-col gap-8">
							<Heading as="h2">Experience the World of Troon®</Heading>

							<div class="grid grid-cols-1 gap-8 lg:grid-cols-3">
								<For each={experiences}>
									{(data) => (
										<article class="group relative flex shrink grow basis-full flex-col gap-4">
											<div class="overflow-hidden rounded">
												<Picture
													src={data.image}
													sizes={[[400, 300]]}
													alt=""
													// eslint-disable-next-line tailwindcss/no-arbitrary-value
													class="pointer-events-none aspect-[4/3] w-full object-cover transition-transform duration-200 group-hover:scale-105"
												/>
											</div>
											<div class="flex flex-col gap-1">
												<Heading as="h3" size="h5">
													{data.title}
												</Heading>
												<p class="text-sm">{data.content}</p>
											</div>
											<LinkButton
												href={data.url}
												class="-ms-2 flex w-fit flex-row gap-2 after:absolute after:inset-0 after:z-0"
											>
												{data.linkText}
												<IconChevronRight />
											</LinkButton>
										</article>
									)}
								</For>
							</div>
						</div>
					</NoHydration>
				</Content>

				<div class="flex flex-col gap-8">
					<Content>
						<Heading as="h2">Featured Courses</Heading>
					</Content>

					<Carousel pixelsPerFrame={0.5}>
						<For each={data()?.featuredFacilities}>
							{(fac) => (
								<CarouselItem>
									<div class="pe-3 md:pe-4">
										<div
											// eslint-disable-next-line tailwindcss/no-arbitrary-value
											class="group relative flex aspect-[2/3] max-h-[45vh] w-full flex-col items-start justify-end overflow-hidden rounded p-4 xl:p-8"
										>
											<Show when={fac.metadata?.hero?.url}>
												{(hero) => (
													<Picture
														draggable={false}
														src={hero()}
														sizes={[[800, 600]]}
														class="absolute inset-0 z-0 size-full object-cover transition-transform duration-200 group-hover:scale-105"
														loading="lazy"
													/>
												)}
											</Show>
											<div class="absolute inset-0 z-10 bg-gradient-to-b from-black/0 via-black/0 to-black/80" />
											<Link
												draggable={false}
												href={`/course/${fac.slug}`}
												class="z-10 text-lg font-semibold text-white after:absolute after:inset-0 after:z-10 md:text-xl"
											>
												{fac.name}
											</Link>
											<p class="z-10 text-sm text-white md:text-base">
												{fac.metadata?.address?.city}, {fac.metadata?.address?.state}
											</p>
										</div>
									</div>
								</CarouselItem>
							)}
						</For>
					</Carousel>
				</div>
			</div>
		</>
	);
}

const experiences: Array<{ image: string; title: string; content: string; url: string; linkText: JSX.Element }> = [
	{
		image: `${getConfigValue('IMAGE_HOST')}/digital/hero/play-troon.jpeg`,
		title: 'Play Troon',
		content:
			'It has never been easier to play your favorite Troon courses. Compare rates and availability across our network.',
		url: '/tee-times',
		linkText: 'Book a tee time',
	},
	{
		image: `${getConfigValue('IMAGE_HOST')}/digital/hero/troon-prive.jpeg`,
		title: 'Troon Privé',
		content:
			'Members of Troon Privé Clubs receive exclusive access to extraordinary golf and lifestyle benefits throughout the world.',
		url: '/member-programs',
		linkText: (
			<>
				Learn more<span class="sr-only"> about Troon Privé</span>
			</>
		),
	},
	{
		image: `${getConfigValue('IMAGE_HOST')}/digital/hero/career.jpeg`,
		title: 'Management Services',
		content: 'See why Troon is the #1 golf and club-related hospitality management company in the world.',
		url: '/management-services',
		linkText: (
			<>
				Learn more<span class="sr-only"> about Troon’s Management Services</span>
			</>
		),
	},
];

const homeQuery = gql(`
query loggedOutHome {
	featuredFacilities: facilities(featured: false, limit: 12) {
		slug
		name
		metadata {
			hero {
				url
			}
			address {
				city
				state
			}
		}
	}
}`);

const getHomeData = cachedQuery(homeQuery);

export const route = { info: { hasHero: true } } satisfies RouteDefinition;
