import { For } from 'solid-js';
import { ImageOverlay } from '../image-overlay';
import { getConfigValue } from '../../modules/config';
import type { ParentProps } from 'solid-js';

type Props = ParentProps;
export function HeroVideo(props: Props) {
	return (
		<div
			// eslint-disable-next-line tailwindcss/no-arbitrary-value
			class="relative flex max-h-min min-h-[70svh] flex-col items-center overflow-hidden bg-brand md:min-h-[75svh]"
		>
			<video
				playsinline
				poster={`${getConfigValue('IMAGE_HOST')}/digital/hero/hero-480p-20240926.jpg`}
				autoplay={!import.meta.env.DEV}
				muted
				loop
				class="absolute size-full object-cover"
			>
				<For each={sources}>
					{(src) => (
						<source
							src={`https://d1lc3bk56q5l6m.cloudfront.net/digital/hero/${src.file}`}
							type={src.type}
							media={src.media}
						/>
					)}
				</For>
			</video>
			<div class="pointer-events-none absolute inset-0 bg-black/30" />
			<div class="pointer-events-none absolute inset-0 bg-gradient-to-b from-black/0 via-black/0 to-black" />
			<div class="z-30 flex w-full grow items-center pb-12 pt-32 text-white">{props.children}</div>
			<ImageOverlay class="absolute -bottom-1" />
		</div>
	);
}

const sources = [
	{ media: '(min-width: 720px)', file: 'hero-480p-vp9-20240916.webm', type: 'video/webm; codecs="vp9"' },
	{ media: '(min-width: 720px)', file: 'hero-480p-vp8-20240916.webm', type: 'video/webm; codecs="vp8"' },
	{ media: '(min-width: 720px)', file: 'hero-480p-20240916.mp4', type: 'video/mp4' },
	{ media: '(max-width: 720px)', file: 'hero-mobile-vp9-20240916.webm', type: 'video/webm; codecs="vp9"' },
	{ media: '(max-width: 720px)', file: 'hero-mobile-vp8-20240916.webm', type: 'video/webm; codecs="vp8"' },
	{ media: '(max-width: 720px)', file: 'hero-mobile-20240916.mp4', type: 'video/mp4' },
];
